

















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'quasar'
import { tasksStore } from '@/store'

const { capitalize } = format

interface ListOption {
  importance: number | null;
  importanceLevel: number;
  label: string;
}

@Component({
  name: 'SelectorList',
  components: {
    TaskImportanceIcon: () => import('@/components/Tasks/TaskImportanceIcon.vue'),
  },
})
export default class SelectorList extends Vue {
  /**
   * Importance value (not level) to be displayed and updated by the component
   */
  @Prop({
    type: Number,
    default: null,
  }) readonly value!: number

  // bodgy bodge for our z-index values vs quasar default ones
  popupContentStyle: Partial<CSSStyleDeclaration> = { zIndex: '9999' }

  levelCaptions: string[] = ['lowest', 'low', 'medium', 'high', 'highest']

  get currentImportanceLevel (): number {
    return tasksStore.getters.importanceOptions.indexOf(this.value)
  }

  get currentImportanceLabel (): string {
    if (this.currentImportanceLevel === -1) return this.$t('common.no').toString()

    const context = this.levelCaptions[this.currentImportanceLevel]
    return capitalize(this.$t(`glossary.importance_${context}`).toString())
  }

  get listOptions (): ListOption[] {
    const mapper = (
      importance: number,
      importanceLevel: number,
    ): ListOption => {
      const context = this.levelCaptions[importanceLevel]
      const label = capitalize(this.$t(`glossary.importance_${context}`).toString())
      return { importance, importanceLevel, label }
    }

    const options = tasksStore.getters.importanceOptions.map(mapper)

    // add 'no importance' option
    options.unshift({
      importance: null,
      label: this.$t('common.no').toString(),
      importanceLevel: -1,
    })

    // most important on top
    return options.reverse()
  }
}
