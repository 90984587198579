import { render, staticRenderFns } from "./SelectorList.vue?vue&type=template&id=36378a2c&"
import script from "./SelectorList.vue?vue&type=script&lang=ts&"
export * from "./SelectorList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QSelect,QItem,QItemSection,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSelect,QItem,QItemSection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
